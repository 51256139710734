// src/App.js
import React from "react";
import Background from "./components/Background";
import MainContent from "./components/MainContent";
import "./App.css";
import Spline from "./components/Spline";
function App() {
  return (
    <>
      <div className="App">
        <Background />
        <MainContent />
      </div>
      <div className="App1">
        <Spline />
      </div>
    </>
  );
}

export default App;
