// spline.js
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const SplineViewer = ({ url, style }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Check if the Spline script is already in the document to prevent duplicates
    const scriptId = "spline-viewer-script";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.type = "module";
      script.src =
        "https://unpkg.com/@splinetool/viewer@1.9.36/build/spline-viewer.js";
      script.onload = () => setIsLoaded(true);
      script.onerror = () =>
        console.error("Failed to load the Spline viewer script.");
      document.body.appendChild(script);

      return () => {
        // Cleanup script only if it was added by this component
        document.getElementById(scriptId) && document.body.removeChild(script);
      };
    } else {
      setIsLoaded(true); // Script is already loaded
    }
  }, []);

  return (
    <>
      {!isLoaded && <p>Loading 3D viewer...</p>}
      {isLoaded && (
        <spline-viewer
          style={style}
          url={url}
          allowZoom="false" // Disables zooming within the viewer
        ></spline-viewer>
      )}
    </>
  );
};

// Define default props for better reusability
SplineViewer.defaultProps = {
  url: "https://prod.spline.design/IoYHhSMBFR9Dd5u4/scene.splinecode",
  style: { zIndex: 10000 },
};

// Prop validation for better maintainability
SplineViewer.propTypes = {
  url: PropTypes.string,
  style: PropTypes.object,
};

export default SplineViewer;
