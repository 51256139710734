// src/components/Background.js
import React, { useEffect, useRef } from "react";
import "./Background.css"; // Se desideri mantenere il CSS, ma lo useremo solo per lo sfondo
import LogoCarousel from "./LogoCarousel";

const Background = () => {
  const canvasRef = useRef(null);
  const stars = useRef([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Inizializza le stelle con dimensione e trasparenza personalizzate
    stars.current = Array.from({ length: 100 }, () => ({
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      size: Math.random() * 1.1, // Cambia qui per modificare lo spessore
      speedX: Math.random() * 0.15 - 0.05, // Velocità più lenta
      speedY: Math.random() * 0.15 - 0.05, // Velocità più lenta
    }));

    const meteors = Array.from({ length: 5 }, () => ({
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      length: Math.random() * 100 + 50,
      speed: Math.random() * 3 + 7, // Maggiore velocità per una caduta più realistica
      angle: Math.PI / 6, // Angolo di caduta più orizzontale
      lineWidth: 0.2, // Spessore delle meteore
    }));

    const animate = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);

      // Draw stars
      stars.current.forEach((star) => {
        context.beginPath();
        context.arc(star.x, star.y, star.size, 0, Math.PI * 2);
        context.fillStyle = `rgba(255, 255, 255, 0.5)`; // Trasparenza delle stelle
        context.fill();

        // Movimento casual delle stelle
        star.x += star.speedX;
        star.y += star.speedY;

        // Controlla i bordi dello schermo e riporta le stelle dall'altra parte
        if (star.x < 0) star.x = window.innerWidth;
        if (star.x > window.innerWidth) star.x = 0;
        if (star.y < 0) star.y = window.innerHeight;
        if (star.y > window.innerHeight) star.y = 0;
      });

      // Draw meteors
      meteors.forEach((meteor) => {
        context.beginPath();
        context.moveTo(meteor.x, meteor.y);
        context.lineTo(
          meteor.x + meteor.length * Math.cos(meteor.angle),
          meteor.y + meteor.length * Math.sin(meteor.angle)
        );
        context.strokeStyle = "rgba(255, 255, 255, 0.4)"; // Trasparenza delle meteore
        context.lineWidth = meteor.lineWidth; // Usa lo spessore definito per le meteore
        context.stroke();

        // Disegna il punto luminoso alla fine della cometa
        const endX = meteor.x + meteor.length * Math.cos(meteor.angle);
        const endY = meteor.y + meteor.length * Math.sin(meteor.angle);

        // Creazione di un gradiente radiale
        const gradient = context.createRadialGradient(
          endX,
          endY,
          0,
          endX,
          endY,
          20 // Maggiore raggio per un gradiente più sfumato
        );
        gradient.addColorStop(0, "rgba(255, 255, 255, 0.1)"); // Colore bianco al centro
        gradient.addColorStop(1, "rgba(255, 255, 255, 0)"); // Trasparente all'esterno

        context.beginPath();
        context.arc(endX, endY, 4, 0, 270); // Punto luminoso con raggio maggiore
        context.fillStyle = gradient; // Applica il gradiente
        context.fill();

        // Aggiorna la posizione della meteora
        meteor.x += meteor.speed * Math.cos(meteor.angle);
        meteor.y += meteor.speed * Math.sin(meteor.angle);
        if (meteor.x > window.innerWidth || meteor.y > window.innerHeight) {
          meteor.x = Math.random() * window.innerWidth;
          meteor.y = -50;
        }
      });

      requestAnimationFrame(animate);
    };

    animate();
  }, []);

  return (
    <div className="background">
      <canvas
        ref={canvasRef}
        width={window.innerWidth}
        height={window.innerHeight}
      ></canvas>
      <div className="planet-curvature"></div>
      {/* <div className="logo-carousel-container">
        <LogoCarousel />
      </div> */}
    </div>
  );
};

export default Background;
