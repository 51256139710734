import React from "react";
import "./LogoCarousel.css"; // Assicurati di creare questo file CSS

const logos = [
  "https://upload.wikimedia.org/wikipedia/commons/e/e8/Tesla_logo.png", // Tesla
  "https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg", // Amazon
  "https://upload.wikimedia.org/wikipedia/commons/4/4f/Boeing_full_logo.svg", // Boeing
  "https://upload.wikimedia.org/wikipedia/commons/3/3e/Ford_logo_flat.svg", // Ford

  "https://upload.wikimedia.org/wikipedia/it/e/e4/Unilever.svg", // Unilever
  "https://upload.wikimedia.org/wikipedia/commons/e/e8/Tesla_logo.png", // Tesla
  "https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg", // Amazon
  "https://upload.wikimedia.org/wikipedia/commons/4/4f/Boeing_full_logo.svg", // Boeing
  "https://upload.wikimedia.org/wikipedia/commons/3/3e/Ford_logo_flat.svg", // Ford

  "https://upload.wikimedia.org/wikipedia/it/e/e4/Unilever.svg", // Unilever
  "https://upload.wikimedia.org/wikipedia/commons/e/e8/Tesla_logo.png", // Tesla
  "https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg", // Amazon
  "https://upload.wikimedia.org/wikipedia/commons/4/4f/Boeing_full_logo.svg", // Boeing
  "https://upload.wikimedia.org/wikipedia/commons/3/3e/Ford_logo_flat.svg", // Ford

  "https://upload.wikimedia.org/wikipedia/it/e/e4/Unilever.svg", // Unilever
  "https://upload.wikimedia.org/wikipedia/commons/e/e8/Tesla_logo.png", // Tesla
  "https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg", // Amazon
  "https://upload.wikimedia.org/wikipedia/commons/4/4f/Boeing_full_logo.svg", // Boeing
  "https://upload.wikimedia.org/wikipedia/commons/3/3e/Ford_logo_flat.svg", // Ford

  "https://upload.wikimedia.org/wikipedia/it/e/e4/Unilever.svg", // Unilever
];

const LogoCarousel = () => {
  return (
    <div className="logo-carousel-container">
      <div className="logo-carousel">
        <div className="logo-track">
          {logos.map((logo, index) => (
            <div className="logo-item" key={index}>
              <img src={logo} alt={`Logo ${index + 1}`} />
            </div>
          ))}
          {/* Ripetere la lista per un effetto di loop continuo */}
          {logos.map((logo, index) => (
            <div className="logo-item" key={`repeat-${index}`}>
              <img src={logo} alt={`Logo ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogoCarousel;
