import React from "react";
import "./MainContent.css";

const illuminateText = (text) => {
  return text.split("").map((letter, index) => (
    <span
      key={index}
      className="badge-text"
      style={{ animationDelay: `${index * 0.1}s` }} // Ritarda l'animazione di ciascuna lettera
    >
      {letter}
    </span>
  ));
};

const MainContent = () => {
  // Funzione per inviare email
  const sendEmail = (subject) => {
    window.location.href = `mailto:alberto.besozzi@geminiautomation.it?subject=${subject}`;
  };

  return (
    <div className="main-content">
      <button
        className="badge-container"
        onClick={() => sendEmail("[Demo] Digital Twin")}
      >
        <span className="badge">New</span>
        <span className="badge-text">
          {illuminateText("Prova la nostra Demo!")} {/* Text con animazione */}
        </span>
      </button>

      <h1>Gemini Automation</h1>
      <p>
        Prevedi il futuro con soluzioni Digital Twin all'avanguardia, progettate
        per trasformare e ottimizzare la tua azienda.
      </p>

      <div className="buttons">
        <button
          className="login-btn"
          onClick={() => sendEmail("[Investitori] Gemini Automation")}
        >
          Opportunità di Investimento
        </button>
        <button
          className="features-btn"
          onClick={() => sendEmail("[Appuntamento] Interesse Digital Twin")}
        >
          Richiedi una Chiamata
        </button>
      </div>

      {/* <div className="user-icons">
        <img
          src="https://randomuser.me/api/portraits/men/1.jpg"
          alt="User 1"
          className="user-icon"
        />
        <img
          src="https://randomuser.me/api/portraits/men/2.jpg"
          alt="User 2"
          className="user-icon"
        />
        <img
          src="https://randomuser.me/api/portraits/women/1.jpg"
          alt="User 3"
          className="user-icon"
        />
        <img
          src="https://randomuser.me/api/portraits/women/2.jpg"
          alt="User 4"
          className="user-icon"
        />
      <div id="spline-viewer-container"></div>
        Unisciti al 62% delle aziende che stanno pianificando di usare soluzioni
        Digital Twin! <br />
        Rimani al passo con la trasformazione digitale.
      </div> */}
    </div>
  );
};

export default MainContent;
